import React from 'react'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './features/Login/Login'
import Snapshot from './features/Snapshot/Snapshot'
import PageNotFound from './features/PageNotFound/PageNotFound'

function App () {
  return (
   <div className="App">
    <Router>
      <Routes>
        <Route path="/" element={<Login inactive={false}/>}/>
        <Route path="/inactive" element={<Login inactive/>}/>
        <Route path="/snapshot" element={<Snapshot />}/>
        <Route path="*" element={<PageNotFound/>}/>
      </Routes>
    </Router>
   </div>
  )
}

export default App
