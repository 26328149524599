import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { setDropdown, selectProject } from './SnapshotSlice'
import { Snapshot } from '../../app/types'

type DropDownProps = {
  snapshots: Snapshot[]
  loading?: boolean
}

const Dropdown: React.FunctionComponent<DropDownProps> = ({ snapshots, loading }: DropDownProps) => {
  const isEmpty = snapshots.length === 0
  const dispatch = useAppDispatch()
  const selectedProject = useAppSelector(selectProject)
  const projectName = selectedProject !== null ? selectedProject.project : 'Select Cloud Project'

  return (
    <div className="field">
      <div className="dropdown is-hoverable">
        <div className="dropdown-trigger">
          <button disabled={isEmpty} className={`button ${loading ? 'is-loading' : ''}`} aria-haspopup="true" aria-controls="dropdown-menu" style={{ width: '450px' }}>
            <span>{projectName}</span>
            <span className="icon is-small">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content" style={{ width: '450px' }}>
            {snapshots.map(snapshot => {
              return (
                (
                  <a onClick={() => {
                    dispatch(setDropdown(snapshot))
                  }} key={snapshot.project} className="dropdown-item">
                    {snapshot.project}
                  </a>
                )
              )
            })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dropdown
