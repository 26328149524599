import React from 'react'
import './PageNotFound.css'
import { Link } from 'react-router-dom'

function PageNotFound () {
  return (
        <div className="PageNotFoundContainer">
            <article className="Words message is-danger">
                <div className="message-header">
                   <p className="title is-3" style={{ color: 'white' }}>Page Not Found</p>
                </div>
                <div className="message-body">
                    <p className="Text">The page you can trying to reach does not exist.</p>
                    <p className="Text">Please use the button below to return to the homepage.</p>
                </div>
            </article>
            <div className="Button">
                <Link to="/">
                    <button className="button is-danger">Return to Login</button>
                </Link>
            </div>
        </div>
  )
}

export default PageNotFound
