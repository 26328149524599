import { openstackProjectsEndpoint, grafanaSnapshotListEndpoint, refreshEndpoint, expiredTokenMsg } from '../../app/constants'
import axios, { AxiosError } from 'axios'

const getRefreshToken = async () => {
  try {
    const refreshToken = window.sessionStorage.getItem('refreshToken')
    const { data } = await axios.post(refreshEndpoint, {}, { headers: { Authorization: `Bearer ${refreshToken}` } })
    window.sessionStorage.setItem('accessToken', data.accessToken)
  } catch (error) { console.log(error) }
}

export const gatherProjects = async () => {
  const accessToken = window.sessionStorage.getItem('accessToken')
  try {
    const data = await axios.get(openstackProjectsEndpoint, { headers: { Authorization: `Bearer ${accessToken}` } })
    return { status: 'OK', data: data.data.projects }
  } catch (error) {
    const err = error as AxiosError
    if ((err.response?.data.msg != null && err.response?.data.msg === expiredTokenMsg) || err.response?.status === 422) {
      await getRefreshToken()
      const accessToken = window.sessionStorage.getItem('accessToken')
      const { data } = await axios.get(openstackProjectsEndpoint, { headers: { Authorization: `Bearer ${accessToken}` } })
      return { status: 'OK', data: data.projects }
    }
    return { status: 'ERROR', data: [] }
  }
}

export const generateSnapshotList = async () => {
  const accessToken = window.sessionStorage.getItem('accessToken')
  try {
    const data = await axios.get(grafanaSnapshotListEndpoint, { headers: { Authorization: `Bearer ${accessToken}` } })
    const dataResponse = data.data.snapshot_list
    return { status: 'OK', data: dataResponse }
  } catch (error) {
    const err = error as AxiosError
    if ((err.response?.data.msg != null && err.response?.data.msg === expiredTokenMsg) || err.response?.status === 422) {
      await getRefreshToken()
      const accessToken = window.sessionStorage.getItem('accessToken')
      const { data } = await axios.get(grafanaSnapshotListEndpoint, { headers: { Authorization: `Bearer ${accessToken}` } })
      const dataResponse = data.data.snapshot_list
      return { status: 'OK', data: dataResponse }
    }
    return { status: 'ERROR', data: false }
  }
}

export default gatherProjects
