/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from 'react'
import './Snapshot.css'
import Footer from '../../components/Footer'
import { Link, Navigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { setUsername, clearAuth, selectAuthorized } from '../Login/LoginSlice'
import { getSnapshotListAsync, clearUrl, selectURL, selectProject, selectStatus, selectGatheredProject, selectAPIstatus, clear, selectAuthorizedToken, selectActive, setActive, setDropdown } from './SnapshotSlice'
import Dropdown from './Dropdown'
import { useIdleTimer } from 'react-idle-timer'
import { timeoutInMinutes } from '../../app/constants'

function HeaderContent () {
  const dispatch = useAppDispatch()

  const clearLogin = () => {
    dispatch(setUsername(''))
    dispatch(clearAuth())
    dispatch(clear())
  }

  return (
      <div className="HeaderContent">
        <nav className="level">
            <div className="level-left">
                <a href="https://www.sdsc.edu/" target="_blank" rel="noreferrer">
                    <img src="https://object.cloud.sdsc.edu/v1/AUTH_bd37bdebf8e34d8f9e5f05761b3cc38b/sdsc_logo/sdsc-horizontal" width="250"/>
                </a>
            </div>
            <div className="level-item">
                <p className="title is-3">Grafana Cloud Usage Report</p>
            </div>
            <div className="level-right icons">
                <div className="general-info-icon">
                    <span className="has-tooltip-left has-tooltipl-multiline" data-tooltip="Usage Reports are generated upon login/refresh and will expire&#10;in 1hr. For best practice, please refresh the page every hour for&#10;the latest data and security purposes.">
                        <i className="fas fa-info-circle"></i>
                    </span>
                </div>
                <div className="confluence-icon">
                    <span className="icon Confluence is-large has-tooltip-bottom" data-tooltip="Confluence Guide">
                        <a href="https://sdsc-ucsd.atlassian.net/l/c/Cyh1GC0a" target="_blank" rel="noreferrer">
                            <i className="fab fa-confluence"></i>
                        </a>
                    </span>
                </div>
                <div className="logout-icon">
                    <Link to="/">
                        <span className="icon logout is-large has-tooltip-bottom" data-tooltip="Sign Out" onClick={() => clearLogin()}>
                            <i className="fas fa-sign-out-alt"></i>
                        </span>
                    </Link>
                </div>
            </div>
        </nav>
        </div>
  )
}

function SnapshotRequest () {
  const dispatch = useAppDispatch()
  const selectedProject = useAppSelector(selectProject)
  const projects = useAppSelector(selectGatheredProject)
  const APIerrors = useAppSelector(selectAPIstatus)
  const checkStatus = useAppSelector(selectStatus)

  const refreshButton = () => {
    dispatch(getSnapshotListAsync())
    dispatch(setDropdown(null))
  }

  return (
        <div className="SnapshotRequest">
            <nav className="level">
                <div className="DropdownBody">
                    <div className="DropdownBodyContent">
                        <div className="level-item">
                            <p className="subtitle is-4">Usage Report:</p>
                        </div>
                        <div className="DropdownField">
                          <Dropdown snapshots={projects} loading={checkStatus === 'loading'}/>
                          {checkStatus === 'loading' &&
                            <progress className="progress is-info" max="100">30%</progress>
                          }
                        </div>
                    </div>
                </div>
                <div className="Refresh level-item">
                    <div className="RefreshButton has-tooltip-left" data-tooltip="Refresh and generate new usage reports">
                        <button className="button is-link" onClick={() => refreshButton()} disabled={APIerrors || selectedProject === null}>Refresh</button>
                    </div>
                </div>
            </nav>
        </div>
  )
}

function Dashboard () {
  const dispatch = useAppDispatch()
  const selectedProject = useAppSelector(selectProject)
  const snapshotUrl = useAppSelector(selectURL)
  const APIerrors = useAppSelector(selectAPIstatus)
  const authorized = useAppSelector(selectAuthorized)
  const isAuthorized = useAppSelector(selectAuthorizedToken)
  const isActive = useAppSelector(selectActive)

  useEffect(() => {
    dispatch(getSnapshotListAsync())
    dispatch(clearUrl())
    dispatch(setActive(true))
  }, [dispatch])

  const handleOnIdle = () => {
    dispatch(setActive(false))
    dispatch(setUsername(''))
    dispatch(clearAuth())
    dispatch(clear())
  }

  useIdleTimer({
    onIdle: handleOnIdle,
    timeout: 1000 * 60 * timeoutInMinutes
  })

  return (
        <div className='DashboardPage'>
            {((authorized !== 'yes' && isAuthorized === false) && isActive) && (<Navigate to="/" replace={true} />)}
            {((authorized !== 'yes' && isAuthorized === false) && !isActive) && (<Navigate to="/inactive" replace={true} />)}
            <div className="DashboardHeader">
                <HeaderContent/>
                <hr className="Solid"/>
            </div>
            {APIerrors &&
                (<article className="message SnapshotError is-danger">
                    <div className="ErrorMessage">
                        An error has occurred when trying to load data. Please refresh the page and try again. If the error persists, please contact <a>support@sdsc.edu</a>
                    </div>
                </article>)
            }
            <div className="Snapshot">
                <SnapshotRequest/>
            </div>
            <div className="DashboardContent">
                {selectedProject === null &&
                    (<div className="DefaultDashboard">
                        <img src="https://object.cloud.sdsc.edu/v1/AUTH_bd37bdebf8e34d8f9e5f05761b3cc38b/Misc/grafana-snapshot-blank" width="100%"/>
                    </div>)
                }
                {selectedProject !== null &&
                    <div className="EmbeddedDashboard">
                        <iframe src={snapshotUrl} height="100%" width="100%"></iframe>
                    </div>
                }
            </div>
            <div className="DashboardFooter">
                <Footer />
            </div>
        </div>
  )
}

export default Dashboard
