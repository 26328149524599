import { flaskLoginEndpoint } from '../../app/constants'
import axios from 'axios'

export const submitLogin = async (username: string, password: string) => {
  try {
    const { data } = await axios.post(flaskLoginEndpoint, { username, password })
    window.sessionStorage.setItem('accessToken', data.accessToken)
    window.sessionStorage.setItem('refreshToken', data.refreshToken)
    return { status: 'OK', data: data }
  } catch (error) {
    console.log(error)
    return { status: 'ERROR', data: false }
  }
}
