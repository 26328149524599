import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import loginReducer from '../features/Login/LoginSlice'
import snapshotReducer from '../features/Snapshot/SnapshotSlice'

export const store = configureStore({
  reducer: {
    login: loginReducer,
    snapshots: snapshotReducer
  }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
