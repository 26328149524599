import React from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { getLoginAsync, selectUsername, selectPassword, selectAuthorized, setUsername, setPassword } from './LoginSlice'
import { Navigate } from 'react-router-dom'
import './Login.css'

type LoginProps = {
    inactive: boolean
  }

const Login: React.FunctionComponent<LoginProps> = ({ inactive }: LoginProps) => {
  const username = useAppSelector(selectUsername)
  const password = useAppSelector(selectPassword)
  const authorized = useAppSelector(selectAuthorized)
  const dispatch = useAppDispatch()

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      dispatch(getLoginAsync({ username, password }))
    }
  }

  return (
        <div className="LoginPage">
            <div className="LoginHeader">
                <Header text={'Grafana Cloud Usage Report'} size='3'/>
            </div>
            <div className="LoginContent">
                <div className="LoginContainer">
                    <div className="SDSClogo">
                        <img src="https://object.cloud.sdsc.edu/v1/AUTH_bd37bdebf8e34d8f9e5f05761b3cc38b/sdsc_logo/sdsc-logo-redblack" height="100px"/>
                    </div>
                    {authorized === 'no' &&
                    (<article className="message LoginError is-danger">
                        <div className="ErrorMessage">
                            Invalid Credentials. Please try again
                        </div>
                    </article>)
                    }
                    {inactive &&
                    (<article className="message LoginError is-danger">
                        <div className="ErrorMessage">
                            Logged out due to inactivity
                        </div>
                    </article>)
                    }
                    <div className="Credentials">
                        <div className="LoginLabels">
                        <label>SDSC Cloud Username</label>
                        </div>
                        <div className="Control">
                            <input className="input"
                                type="text"
                                value={username}
                                onChange={(e) => dispatch(setUsername(e.target.value))}
                            />
                        </div>
                        <div className="LoginLabels">
                        <label>SDSC Cloud Password</label>
                        </div>
                        <div className="Control">
                            <input className="input"
                              type="password"
                              value={password}
                              onChange={(e) => dispatch(setPassword(e.target.value))}
                              onKeyDown={(e) => handleKeyPress(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className="LoginButtonContainer">
                    <button className="button is-link" onClick={() => dispatch(getLoginAsync({ username, password }))} disabled={ username === '' || password === ''}>Login</button>
                </div>
                {authorized === 'yes' && (<Navigate to="/snapshot" replace={true} />)}
            </div>
            <div className="LoginFooter">
                <Footer />
            </div>
        </div>
  )
}

export default Login
