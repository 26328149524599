import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from './../../app/store'
import { generateSnapshotList } from './SnapshotAPI'
import { Snapshot } from '../../app/types'
export interface SnapshotState {
  gatheredProjects: Snapshot[];
  snapshotUrl: string;
  status: 'idle' | 'loading' | 'failed' |'success';
  selectedProject: Snapshot | null;
  APIerrors: boolean;
  isAuthorized: boolean;
  isActive: boolean
}

const initialState: SnapshotState = {
  gatheredProjects: [],
  snapshotUrl: '',
  status: 'idle',
  selectedProject: null,
  APIerrors: false,
  isAuthorized: window.sessionStorage.getItem('accessToken') !== null,
  isActive: true
}

export const getSnapshotListAsync = createAsyncThunk(
  'projects/fetch',
  async () => {
    const { status, data } = await generateSnapshotList()
    return { status, data }
  }
)

export const SnapshotSlice = createSlice({
  name: 'snapshots',
  initialState: initialState,
  reducers: {
    setDropdown: (state, action) => {
      state.selectedProject = action.payload
    },
    clearUrl: (state) => {
      state.snapshotUrl = ''
    },
    clear: (state) => {
      state.gatheredProjects = []
      state.snapshotUrl = ''
      state.status = 'idle'
      state.selectedProject = null
      state.APIerrors = false
      window.sessionStorage.clear()
    },
    setActive: (state, action) => {
      state.isActive = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSnapshotListAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getSnapshotListAsync.fulfilled, (state, action) => {
        state.gatheredProjects = []
        state.selectedProject = null
        if (action.payload.status === 'ERROR') {
          state.APIerrors = true
        } else {
          state.gatheredProjects = action.payload.data
          state.status = 'idle'
        }
      })
  }
})

export const { setDropdown, clearUrl, clear, setActive } = SnapshotSlice.actions
export const selectGatheredProject = (state: RootState) => state.snapshots.gatheredProjects
export const selectURL = (state: RootState) => state.snapshots.selectedProject?.modified_url
export const selectStatus = (state: RootState) => state.snapshots.status
export const selectProject = (state: RootState) => state.snapshots.selectedProject
export const selectAPIstatus = (state: RootState) => state.snapshots.APIerrors
export const selectAuthorizedToken = (state: RootState) => state.snapshots.isAuthorized
export const selectActive = (state: RootState) => state.snapshots.isActive

export default SnapshotSlice.reducer
