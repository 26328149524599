import React from 'react'
import PropTypes, { InferProps } from 'prop-types'

function Header ({ text, size, align }: InferProps<typeof Header.propTypes>) {
  return (
    <div className={`title is-${size}`} style={{ textAlign: align }}>
      {text}
      <hr className="solidline"/>
    </div>
  )
}

Header.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  align: PropTypes.any
}

Header.defaultProps = {
  size: '3',
  align: 'center'
}

export default Header
