import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './../../app/store'
import { submitLogin } from './LoginAPI'

export interface LoginState {
  username: string;
  password: string;
  status: 'idle' | 'loading' | 'failed';
  authorized: 'yes' | 'no' | 'idle';
}

const initialState: LoginState = {
  username: '',
  password: '',
  status: 'idle',
  authorized: 'idle'
}

export interface LoginForm {
    username: string;
    password: string;
}

export const getLoginAsync = createAsyncThunk(
  'login/fetch',
  async (credentials: LoginForm) => {
    const { status, data } = await submitLogin(credentials.username, credentials.password)
    return { status, data }
  }
)

export const LoginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload
    },
    setPassword: (state, action:PayloadAction<string>) => {
      state.password = action.payload
    },
    clearAuth: (state) => {
      state.authorized = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoginAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getLoginAsync.fulfilled, (state, action) => {
        if (action.payload.data.authorized === true) {
          state.authorized = 'yes'
        } else {
          state.authorized = 'no'
        }
        state.password = ''
        state.status = 'idle'
      })
  }
})

export const { setUsername, setPassword, clearAuth } = LoginSlice.actions
export const selectUsername = (state: RootState) => state.login.username
export const selectPassword = (state: RootState) => state.login.password
export const selectAuthorized = (state: RootState) => state.login.authorized

export default LoginSlice.reducer
