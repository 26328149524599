import React from 'react'

function Footer () {
  return (
    <div style={{ textAlign: 'center' }}>
      <hr className="solidline"/>
      <p>For questions and issues, please contact <a href="mailto:support@sdsc.edu">support@sdsc.edu</a>.</p>
    </div>
  )
}

export default Footer
